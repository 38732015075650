.p-tree {
    width  : 100%;
    border : none;
}

body .p-tree.ui-widget-content {
    border : none !important;
}

span.p-treenode-label {
    font-family   : Poppins !important;
    line-height   : 24px !important;
    font-size     : 14px !important;
    padding-left  : 5px !important;
    padding-right : 5px !important;
}

span.p-treenode-icon {
    line-height : 24px !important;
    font-size   : 1.2rem !important;
}

.p-tree .ui-chkbox .ui-chkbox-icon {
    margin-left : 0;
}

.p-tree .p-treenode-children {
    padding-left : 20px !important;
}

.hidden-tree-node {
    display : none;
}

p-tree[selectionmode="checkbox"] .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background-color : transparent !important;
    color            : #111111 !important;
    font-weight      : 600 !important;
}

span.p-treenode-label {
    line-height : 18px !important;
    font-size   : 13px !important;
}

body .p-tree .p-tree-container .p-treenode {
    padding : 0;
}


body .p-tree .p-tree-container .p-treenode .p-treenode-content:hover {
    background-color   : rgba(0, 0, 0, .15) !important;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    -webkit-box-shadow : none;
    -moz-box-shadow    : none;
    box-shadow         : none;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
    -webkit-box-shadow : none;
    -moz-box-shadow    : none;
    box-shadow         : none;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .ui-chkbox .ui-chkbox-icon {
    margin : -1px 0 0 -1px;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-checkbox-box.p-highlight {
    color        : #5283F3 !important;
    border-color : #5283F3 !important;
    background   : #5283F3 !important;
}

body .ui-chkbox {
    width  : 16px;
    height : 16px;
}

body .ui-chkbox .ui-chkbox-box {
    width  : 16px;
    height : 16px;
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
    font-size : 16px;
}
