/* You can add global styles to this file, and also import other style files */
@import "~ng-zorro-antd/ng-zorro-antd.min.css";
@import "cs-styles.css";
@import "//at.alicdn.com/t/c/font_3122651_fi3fce5qbzq.css";
/*@import "//at.alicdn.com/t/c/font_3122651_ciol1fq4upd.css";*/
/*@font-face {
    font-family : 'SanFrancisco';
    font-style  : normal;
    font-weight : 300;
    src         : url('~assets/fonts/San-Francisco/sanfranciscodisplay-black-3.ttf'); !* IE9 Compat Modes *!
}*/

.swal2-container {
    z-index: 999999999999999 !important;
    overflow-y : hidden !important;
}

.p-tooltip .p-tooltip-text {
    background: #495057 !important;
    color: #ffffff  !important;
    font-size: 1rem;
}
.margin-left-5 {
    margin-left : 5px !important;
}

.margin-right-5 {
    margin-right : 5px !important;
}

.margin-top-5 {
    margin-top : 5px !important;
}

.margin-top-15 {
    margin-top : 15px !important;
}

.margin-bottom-5 {
    margin-bottom : 5px !important;
}

.margin-bottom-5 {
    margin-bottom : 5px !important;
}

.margin-bottom-15 {
    margin-bottom : 15px !important;
}

.padding-left-0 {
    padding-left : 0 !important;
}

.padding-left-5 {
    padding-left : 5px !important;
}

.padding-right-5 {
    padding-right : 5px !important;
}

.padding-top-5 {
    padding-top : 5px !important;
}

.padding-bottom-5 {
    padding-bottom : 5px !important;
}

.padding-bottom-15 {
    padding-bottom : 15px !important;
}

.width-percent-5 {
    width : 5%;
}

.width-percent-10 {
    width : 10%;
}

.width-percent-15 {
    width : 15%;
}

.width-percent-20 {
    width : 20%;
}

.width-percent-30 {
    width : 30%;
}

.width-percent-40 {
    width : 40%;
}

.width-percent-50 {
    width : 50%;
}

.width-percent-100 {
    width : 100%;
}

/* Sweet alert */

.swal2-title {
    display : inline-block !important;
}

body.swal2-height-auto {
    height : inherit !important;
}

.swal2-popup.swal2-toast {
    padding : 10px 12px !important;
    display : block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align : left !important;
    margin     : 0 !important;
    width: auto !important;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-bottom : 4px !important;
}

.swal2-icon.swal2-info::before,
.swal2-icon.swal2-warning::before,
.swal2-icon.swal2-success::before,
.swal2-icon.swal2-error::before {
    content : "";
}

@keyframes glowing {
    0% {
        opacity : 0.3;
    }

    50% {
        opacity : 1;
    }

    100% {
        opacity : 0.3;
    }
}

.glowing {
    animation : glowing 2s infinite;
}

.root-initial-loader .overlay {
    position         : absolute;
    left             : 0;
    top              : 0;
    right            : 0;
    bottom           : 0;
    background-color : rgba(51, 51, 51, 0.8);
    z-index          : 99999 !important;
    width            : 100%;
    height           : 100%;
}

.root-initial-loader i {
    position      : absolute;
    color         : rgb(91, 167, 234);
    z-index       : 100000 !important;
    top           : 50%;
    left          : 50%;
    -ms-transform : translate(-50%, -50%);
    transform     : translate(-50%, -50%);
}

.root-initial-loader .fa-spin-2x {
    -webkit-animation : fa-spin 1s infinite linear;
    animation         : fa-spin 1s infinite linear;
}

ngx-spinner .loading-text {
    font-weight : bold;
    color       : white;
    font-size   : 1.3em;
}

::ng-deep .p-checkbox .p-checkbox-box.p-highlight {
    border-color : #007BFF !important;
    background   : #007BFF !important;
}

/*字体引用*/
