.ui-contextmenu ul li a {
    text-decoration : none;
    color           : #000000;
}

.ui-contextmenu .ui-menuitem-text {
    font-family : Poppins !important;
    font-size   : 13px !important;
}

.ui-contextmenu .ui-menuitem :hover {
    background-color : #F4F5F8;
}
