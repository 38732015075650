
 .custom-prompt-box {
    top: 20px;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    z-index: 999999999999;
}
 .custom-loader {
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     width: 100vw;
     height: 100vh;
     z-index: 999999999999;
     position: fixed;
     top: 0;
     left: 0;
     background-color: rgba(0, 0, 0, 0.4);
 }

