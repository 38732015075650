.cs-no-user-edit {
    -webkit-user-drag   : none; /* 禁止拖拽 */
    -webkit-user-select : none; /* 禁止选中 */
}

.cs-btn {
    border-radius    : 2px !important;
    background-color : #5282F3 !important;
    border           : 0 !important;
    color            : white !important;
    font-size        : 12px !important;
    width            : auto !important;
    min-width        : 60px !important;
    height           : 28px !important;
    padding          : 0 8px !important;
}

.cs-btn:hover {
    background-color : #E1F0FF !important;
    color            : #5282F3 !important;
}

.cs-btn:disabled {
    background-color : #C3C7CD !important;
    color            : #FFFFFF !important;
}

.cs-btn > i {
    color         : #FFFFFF;
    padding-right : 0.35rem;
    line-height   : 1;
    font-size     : 12px;
}

.cs-btn:hover > i {
    color : #5282F3 !important;
}

.cs-btn-light {
    border-radius    : 2px !important;
    background-color : #E1F0FF !important;
    border           : 0 !important;
    color            : #3699FF !important;
    font-size        : 12px !important;
    width            : auto !important;
    min-width        : 60px !important;
    height           : 28px !important;
    padding          : 0 8px !important;
}

.cs-btn-light:hover {
    background-color : #3699FF !important;
    color            : #FFFFFF !important;
}

.cs-btn > a {
    color     : rgb(255, 255, 255) !important;
    font-size : 12px !important;
}

.m-0 {
    margin : 0 !important;
}

.p-0 {
    padding : 0 !important;
}

.btn-tab {
    border-radius    : 2px !important;
    background-color : rgba(82, 130, 243, 0) !important;
    border           : 0 !important;
    color            : #5E80EB !important;
    font-size        : 14px !important;
    width            : auto !important;
    min-width        : 60px !important;
    height           : 28px !important;
    padding          : 0 8px;
}

.float-right {
    float : right;
}

.cs-button-bar {
    justify-content  : left;
    padding          : 8px 18px 14px;
    background-color : #FFFFFF;
    border           : 0;
}

.cs-inner-row {
    margin           : 0 !important;
    padding          : 15px;
    background-color : rgb(226, 231, 239);
}

.cs-card-left {
    padding          : 0 !important;
    margin           : 0 !important;
    background-color : #FFFFFF;
}

.cs-card-right {
    padding          : 0 !important;
    margin           : 0 !important;
    border-left      : 1px #CCCCCC solid;
    background-color : #FFFFFF;
}

.hidden-danger-font {
    padding-top : 12px;
    text-align  : center;
    color       : gray;
}

/* 手型光标 */
.cs-pointer {
    cursor : pointer;
}

.cs-chevron-style {
    color   : #5E78E1;
    padding : 17px 17px 17px 2.25rem;
    width   : 16px;
    height  : 16px;
    cursor  : pointer;
}

.cs-gray-hr {
    background-color : #CCCCCC;
    height           : 1px;
    border           : 0;
    padding          : 0;
    margin           : 0;
}

.cs-table-bar {
    height : 50px;
}

.cs-panel-title {
    font-size   : 18px;
    color       : #312520;
    font-weight : bold;
}

/*底色*/
body {
    background-color : #E2E7EF !important;
}

/*!*组件禁用*!*/
/*.p-inputtext:disabled {*/
/*    border-bottom: 1px solid rgb(229 229 229) !important;*/
/*    color: rgba(178 ,178, 178 ,.8) !important;*/
/*    background-color: white;*/
/*}*/
/*.form-control {*/
/*    border-bottom: 1px solid #999999  !important;*/
/*}*/

/*单据输入框边距*/
.object-bill .col-field {
    padding : 3px 20px !important;
    min-width: 250px;
}

.object-bill .col-group {
    padding: 0 !important;
    margin-bottom: 5px;
}

.object-bill .col-lg-2 {
    padding : 0 20px 0 20px !important;
}

.object-bill .col-sm-6 {
    padding : 0 20px 0 20px !important;
}

/*字体样式*/
.object-bill .p-inputtext {
    font-family : Roboto, "San Francisco", "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragina Sans GB", "WenQuanYi Micro Hei", "microsoft yahei ui", "microsoft yahei", sans-serif !important;
    font-weight : 900 !important;
}

/*面包屑选择器底色*/
#cs_bookmark {
    box-shadow : 2px -7px 10px 9px rgba(0, 0, 0, .3) !important;
}

.cs-single-line {
    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
}

