/* poppins-300 - devanagari_latin-ext_latin */
@font-face {
    font-family : 'Poppins';
    font-style  : normal;
    font-weight : 300;
    src         : url('./Poppins/poppins-v5-devanagari_latin-ext_latin-300.eot'); /* IE9 Compat Modes */
    src         : local('Poppins Light'), local('Poppins-Light'), url('./Poppins/poppins-v5-devanagari_latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-regular - devanagari_latin-ext_latin */
@font-face {
    font-family : 'Poppins';
    font-style  : normal;
    font-weight : 400;
    src         : url('./Poppins/poppins-v5-devanagari_latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src         : local('Poppins Regular'), local('Poppins-Regular'), url('./Poppins/poppins-v5-devanagari_latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-500 - devanagari_latin-ext_latin */
@font-face {
    font-family : 'Poppins';
    font-style  : normal;
    font-weight : 500;
    src         : url('./Poppins/poppins-v5-devanagari_latin-ext_latin-500.eot'); /* IE9 Compat Modes */
    src         : local('Poppins Medium'), local('Poppins-Medium'), url('./Poppins/poppins-v5-devanagari_latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-600 - devanagari_latin-ext_latin */
@font-face {
    font-family : 'Poppins';
    font-style  : normal;
    font-weight : 600;
    src         : url('./Poppins/poppins-v5-devanagari_latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src         : local('Poppins SemiBold'), local('Poppins-SemiBold'), url('./Poppins/poppins-v5-devanagari_latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-700 - devanagari_latin-ext_latin */
@font-face {
    font-family : 'Poppins';
    font-style  : normal;
    font-weight : 700;
    src         : url('./Poppins/poppins-v5-devanagari_latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src         : local('Poppins Bold'), local('Poppins-Bold'), url('./Poppins/poppins-v5-devanagari_latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Poppins/poppins-v5-devanagari_latin-ext_latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}
