/* .bs-datepicker */
.bs-datepicker {
    display     : flex;
    align-items : stretch;
    flex-flow   : row wrap;
    background  : #FFFFFF;
    box-shadow  : 0 0 10px 0 #AAAAAA;
    position    : relative;
    z-index     : 1;
    /* button */
    /* .bs-datepicker-head */
    /* .bs-datepicker-body */
    /* .current-timedate */
    /* .bs-datepicker-multiple */
    /* .bs-datepicker-btns */
    /*.bs-datepicker-custom-range */
    /* .bs-datepicker-predefined-btns */
    /* .is-other-month */
    /* .bs-datepicker-buttons */
}

.bs-datepicker:after {
    clear   : both;
    content : '';
    display : block;
}

.bs-datepicker bs-day-picker {
    float : left;
}

.bs-datepicker button:hover,
.bs-datepicker button:focus,
.bs-datepicker button:active,
.bs-datepicker input:hover,
.bs-datepicker input:focus,
.bs-datepicker input:active,
.bs-datepicker-btns button:hover,
.bs-datepicker-btns button:focus,
.bs-datepicker-btns button:active,
.bs-datepicker-predefined-btns button:active,
.bs-datepicker-predefined-btns button:focus {
    outline : none;
}

.bs-datepicker-head {
    min-width     : 270px;
    height        : 50px;
    padding       : 10px;
    border-radius : 3px 3px 0 0;
    text-align    : justify;
    /* .bs-datepicker-head button */
}

.bs-datepicker-head:after {
    content        : "";
    display        : inline-block;
    vertical-align : top;
    width          : 100%;
}

.bs-datepicker-head button {
    display        : inline-block;
    vertical-align : top;
    padding        : 0;
    height         : 30px;
    line-height    : 30px;
    border         : 0;
    background     : transparent;
    text-align     : center;
    cursor         : pointer;
    color          : #FFFFFF;
    transition     : 0.3s;
}

.bs-datepicker-head button[disabled], .bs-datepicker-head button[disabled]:hover, .bs-datepicker-head button[disabled]:active {
    background : rgba(221, 221, 221, 0.3);
    color      : #F5F5F5;
    cursor     : not-allowed;
}

.bs-datepicker-head button.next, .bs-datepicker-head button.previous {
    border-radius : 50%;
    width         : 30px;
    height        : 30px;
}

.bs-datepicker-head button.next span, .bs-datepicker-head button.previous span {
    font-size     : 28px;
    line-height   : 1;
    display       : inline-block;
    position      : relative;
    height        : 100%;
    width         : 100%;
    border-radius : 50%;
}

.bs-datepicker-head button.current {
    border-radius : 15px;
    max-width     : 155px;
    padding       : 0 13px;
}

.bs-datepicker-head button:hover {
    background-color : rgba(0, 0, 0, 0.1);
}

.bs-datepicker-head button:active {
    background-color : rgba(0, 0, 0, 0.2);
}

.bs-datepicker-body {
    padding       : 10px;
    border-radius : 0 0 3px 3px;
    min-height    : 232px;
    min-width     : 278px;
    border        : 1px solid #E9EDF0;
    /* .bs-datepicker-body table */
}

.bs-datepicker-body .days.weeks {
    position : relative;
    z-index  : 1;
}

.bs-datepicker-body table {
    width           : 100%;
    border-collapse : separate;
    border-spacing  : 0;
    /* .bs-datepicker-body table.days */
    /* .bs-datepicker-body table.weeks */
}

.bs-datepicker-body table th {
    font-size   : 13px;
    color       : #9AAEC1;
    font-weight : 400;
    text-align  : center;
}

.bs-datepicker-body table td {
    color      : #54708B;
    text-align : center;
    position   : relative;
    padding    : 0;
}

.bs-datepicker-body table td span {
    display             : block;
    margin              : 0 auto;
    font-size           : 13px;
    border-radius       : 50%;
    position            : relative;
    /*z-index: 1;*/
    -moz-user-select    : none;
    -webkit-user-select : none;
    -ms-user-select     : none;
}

.bs-datepicker-body table td:not(.disabled) span {
    cursor : pointer;
}

.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
    background-color : #E9EDF0;
    transition       : 0s;
}

.bs-datepicker-body table td span.disabled,
.bs-datepicker-body table td.disabled span {
    color : #9AAEC1;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-body table td.selected span {
    color : #FFFFFF;
}

.bs-datepicker-body table td.active {
    position : relative;
}

.bs-datepicker-body table td.active.select-start:before {
    left : 35%;
}

.bs-datepicker-body table td.active.select-end:before {
    left : -85%;
}

.bs-datepicker-body table td span.active.select-start:after,
.bs-datepicker-body table td span.active.select-end:after,
.bs-datepicker-body table td.active.select-start span:after,
.bs-datepicker-body table td.active.select-end span:after {
    content       : "";
    display       : block;
    position      : absolute;
    z-index       : -1;
    width         : 100%;
    height        : 100%;
    transition    : 0.3s;
    top           : 0;
    border-radius : 50%;
}

.bs-datepicker-body table td:before,
.bs-datepicker-body table td span:before {
    content    : "";
    display    : block;
    position   : absolute;
    z-index    : -1;
    top        : 6px;
    bottom     : 6px;
    left       : -2px;
    right      : -2px;
    box-sizing : content-box;
    background : transparent;
}

.bs-datepicker-body table td.active.select-start + td.active:before {
    left : -20%;
}

.bs-datepicker-body table td:last-child.active:before {
    border-radius : 0 3px 3px 0;
    width         : 125%;
    left          : -25%;
}

.bs-datepicker-body table td span[class*="select-"],
.bs-datepicker-body table td[class*="select-"] span {
    border-radius : 50%;
    color         : #FFFFFF;
}

.bs-datepicker-body table.days td.active:not(.select-start):before, .bs-datepicker-body table.days td.in-range:not(.select-start):before,
.bs-datepicker-body table.days span.active:not(.select-start):before,
.bs-datepicker-body table.days span.in-range:not(.select-start):before {
    background : #E9EDF0;
}

.bs-datepicker-body table.days span {
    width       : 32px;
    height      : 32px;
    line-height : 32px;
}

.bs-datepicker-body table.days span.select-start {
    z-index : 2;
}

.bs-datepicker-body table.days span.is-highlighted.in-range:before, .bs-datepicker-body table.days span.in-range.select-end:before {
    background : none;
    right      : 0;
    left       : 0;
}

.bs-datepicker-body table.days td.select-start + td.select-end:before,
.bs-datepicker-body table.days td.select-start + td.is-highlighted:before,
.bs-datepicker-body table.days td.active + td.is-highlighted:before,
.bs-datepicker-body table.days td.active + td.select-end:before,
.bs-datepicker-body table.days td.in-range + td.is-highlighted:before,
.bs-datepicker-body table.days td.in-range + td.select-end:before {
    background : #E9EDF0;
    width      : 100%;
}

.bs-datepicker-body table.weeks tr td:nth-child(2).active:before {
    border-radius : 3px 0 0 3px;
    left          : 0;
    width         : 100%;
}

.bs-datepicker-body table:not(.weeks) tr td:first-child:before {
    border-radius : 3px 0 0 3px;
}

.bs-datepicker-body table.years td span {
    width       : 46px;
    height      : 46px;
    line-height : 45px;
    margin      : 0 auto;
}

.bs-datepicker-body table.years tr:not(:last-child) td span {
    margin-bottom : 8px;
}

.bs-datepicker-body table.months td {
    height : 52px;
}

.bs-datepicker-body table.months td span {
    padding       : 6px;
    border-radius : 15px;
}

.bs-datepicker .current-timedate {
    color               : #54708B;
    font-size           : 15px;
    text-align          : center;
    height              : 30px;
    line-height         : 30px;
    border-radius       : 20px;
    border              : 1px solid #E9EDF0;
    margin-bottom       : 10px;
    cursor              : pointer;
    text-transform      : uppercase;
    -moz-user-select    : none;
    -webkit-user-select : none;
    -ms-user-select     : none;
}

.bs-datepicker .current-timedate span:not(:empty):before {
    content        : "";
    width          : 15px;
    height         : 16px;
    display        : inline-block;
    margin-right   : 4px;
    vertical-align : text-bottom;
    background     : url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC);
}

.bs-datepicker-multiple {
    display       : inline-block;
    border-radius : 4px 0 0 4px;
}

.bs-datepicker-multiple + .bs-datepicker-multiple {
    margin-left : 10px;
}

.bs-datepicker-multiple .bs-datepicker {
    box-shadow : none;
    position   : relative;
}

.bs-datepicker-multiple .bs-datepicker:not(:last-child) {
    padding-right : 10px;
}

.bs-datepicker-multiple .bs-datepicker + .bs-datepicker:after {
    content    : "";
    display    : block;
    width      : 14px;
    height     : 10px;
    background : url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=);
    position   : absolute;
    top        : 25px;
    left       : -8px;
}

.bs-datepicker-multiple .bs-datepicker .left {
    float : left;
}

.bs-datepicker-multiple .bs-datepicker .right {
    float : right;
}

.bs-datepicker-container {
    padding : 15px;
}

.bs-datepicker-custom-range {
    padding    : 15px;
    background : #EEEEEE;
}

.bs-datepicker-predefined-btns button {
    width            : 100%;
    display          : block;
    height           : 30px;
    background-color : #9AAEC1;
    border-radius    : 4px;
    color            : #FFFFFF;
    border           : 0;
    margin-bottom    : 10px;
    padding          : 0 18px;
    text-align       : left;
    transition       : 0.3s;
}

.bs-datepicker-predefined-btns button:active, .bs-datepicker-predefined-btns button:hover {
    background-color : #54708B;
}

.bs-datepicker .is-other-month {
    color : rgba(0, 0, 0, 0.25);
}

.bs-datepicker-buttons {
    display         : flex;
    flex-flow       : row wrap;
    justify-content : flex-end;
    padding-top     : 10px;
    border-top      : 1px solid #E9EDF0;
}

.bs-datepicker-buttons .btn-default {
    margin-left : 10px;
}

/* .bs-timepicker */
.bs-timepicker-container {
    padding : 10px 0;
}

.bs-timepicker-label {
    color         : #54708B;
    margin-bottom : 10px;
}

.bs-timepicker-controls {
    display        : inline-block;
    vertical-align : top;
    margin-right   : 10px;
}

.bs-timepicker-controls button {
    width            : 20px;
    height           : 20px;
    border-radius    : 50%;
    border           : 0;
    background-color : #E9EDF0;
    color            : #54708B;
    font-size        : 16px;
    font-weight      : 700;
    vertical-align   : middle;
    line-height      : 0;
    padding          : 0;
    transition       : 0.3s;
}

.bs-timepicker-controls button:hover {
    background-color : #D5DADD;
}

.bs-timepicker-controls input {
    width         : 35px;
    height        : 25px;
    border-radius : 13px;
    text-align    : center;
    border        : 1px solid #E9EDF0;
}

.bs-timepicker .switch-time-format {
    text-transform : uppercase;
    min-width      : 54px;
    height         : 25px;
    border-radius  : 20px;
    border         : 1px solid #E9EDF0;
    background     : #FFFFFF;
    color          : #54708B;
    font-size      : 13px;
}

.bs-timepicker .switch-time-format img {
    vertical-align : initial;
    margin-left    : 4px;
}

bs-datepicker-container,
bs-daterangepicker-container {
    z-index : 1080;
}

/* screen size < 1024px */
@media (max-width : 768px) {
    .bs-datepicker-multiple {
        display : flex;
    }

    .bs-datepicker-multiple + .bs-datepicker-multiple {
        margin-top  : 10px;
        margin-left : 0;
    }
}

/* theming */
.theme-default .bs-datepicker-head {
    background-color : #5867DD;
}

.theme-default .bs-datepicker-body table td span.selected,
.theme-default .bs-datepicker-body table td.selected span,
.theme-default .bs-datepicker-body table td span[class*="select-"]:after,
.theme-default .bs-datepicker-body table td[class*="select-"] span:after {
    background-color : #5867DD;
}

.theme-default .bs-datepicker-body table td.week span {
    color : #5867DD;
}

.theme-green .bs-datepicker-head {
    background-color : #34BFA3;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
    background-color : #34BFA3;
}

.theme-green .bs-datepicker-body table td.week span {
    color : #34BFA3;
}

.theme-blue .bs-datepicker-head {
    background-color : #36A3F7;
}

.theme-blue .bs-datepicker-body table td span.selected,
.theme-blue .bs-datepicker-body table td.selected span,
.theme-blue .bs-datepicker-body table td span[class*="select-"]:after,
.theme-blue .bs-datepicker-body table td[class*="select-"] span:after {
    background-color : #36A3F7;
}

.theme-blue .bs-datepicker-body table td.week span {
    color : #36A3F7;
}

.theme-dark-blue .bs-datepicker-head {
    background-color : #337AB7;
}

.theme-dark-blue .bs-datepicker-body table td span.selected,
.theme-dark-blue .bs-datepicker-body table td.selected span,
.theme-dark-blue .bs-datepicker-body table td span[class*="select-"]:after,
.theme-dark-blue .bs-datepicker-body table td[class*="select-"] span:after {
    background-color : #337AB7;
}

.theme-dark-blue .bs-datepicker-body table td.week span {
    color : #337AB7;
}

.theme-pink .bs-datepicker-head {
    background-color : #F4516C;
}

.theme-pink .bs-datepicker-body table td span.selected,
.theme-pink .bs-datepicker-body table td.selected span,
.theme-pink .bs-datepicker-body table td span[class*="select-"]:after,
.theme-pink .bs-datepicker-body table td[class*="select-"] span:after {
    background-color : #F4516C;
}

.theme-pink .bs-datepicker-body table td.week span {
    color : #F4516C;
}

.theme-yellow .bs-datepicker-head {
    background-color : #FFB822;
}

.theme-yellow .bs-datepicker-body table td span.selected,
.theme-yellow .bs-datepicker-body table td.selected span,
.theme-yellow .bs-datepicker-body table td span[class*="select-"]:after,
.theme-yellow .bs-datepicker-body table td[class*="select-"] span:after {
    background-color : #FFB822;
}

.theme-yellow .bs-datepicker-body table td.week span {
    color : #FFB822;
}

/* THEME-8 */
.theme-theme8 .bs-datepicker-head {
    background-color : #5867DD;
}

.theme-theme8 .bs-datepicker-body table td span.selected,
.theme-theme8 .bs-datepicker-body table td.selected span,
.theme-theme8 .bs-datepicker-body table td span[class*="select-"]:after,
.theme-theme8 .bs-datepicker-body table td[class*="select-"] span:after {
    background-color : #5867DD;
}

.theme-theme8 .bs-datepicker-body table td.week span {
    color : #5867DD
}

/* THEME-2 */
.theme-theme2 .bs-datepicker-head {
    background-color : #5867DD;
}

.theme-theme2 .bs-datepicker-body table td span.selected,
.theme-theme2 .bs-datepicker-body table td.selected span,
.theme-theme2 .bs-datepicker-body table td span[class*="select-"]:after,
.theme-theme2 .bs-datepicker-body table td[class*="select-"] span:after {
    background-color : #5867DD;
}

.theme-theme2 .bs-datepicker-body table td.week span {
    color : #5867DD
}

/* THEME-5 */
.theme-theme5 .bs-datepicker-head {
    background-color : #5867DD;
}

.theme-theme5 .bs-datepicker-body table td span.selected,
.theme-theme5 .bs-datepicker-body table td.selected span,
.theme-theme5 .bs-datepicker-body table td span[class*="select-"]:after,
.theme-theme5 .bs-datepicker-body table td[class*="select-"] span:after {
    background-color : #5867DD;
}

.theme-theme5 .bs-datepicker-body table td.week span {
    color : #5867DD
}

/* THEME-11 */
.theme-theme11 .bs-datepicker-head {
    background-color : #5867DD;
}

.theme-theme11 .bs-datepicker-body table td span.selected,
.theme-theme11 .bs-datepicker-body table td.selected span,
.theme-theme11 .bs-datepicker-body table td span[class*="select-"]:after,
.theme-theme11 .bs-datepicker-body table td[class*="select-"] span:after {
    background-color : #5867DD;
}

.theme-theme11 .bs-datepicker-body table td.week span {
    color : #5867DD
}

/* THEME-3 */
.theme-theme3 .bs-datepicker-head {
    background-color : #5867DD;
}

.theme-theme3 .bs-datepicker-body table td span.selected,
.theme-theme3 .bs-datepicker-body table td.selected span,
.theme-theme3 .bs-datepicker-body table td span[class*="select-"]:after,
.theme-theme3 .bs-datepicker-body table td[class*="select-"] span:after {
    background-color : #5867DD;
}

.theme-theme3 .bs-datepicker-body table td.week span {
    color : #5867DD
}

/* THEME-6 */
.theme-theme6 .bs-datepicker-head {
    background-color : #5867DD;
}

.theme-theme6 .bs-datepicker-body table td span.selected,
.theme-theme6 .bs-datepicker-body table td.selected span,
.theme-theme6 .bs-datepicker-body table td span[class*="select-"]:after,
.theme-theme6 .bs-datepicker-body table td[class*="select-"] span:after {
    background-color : #5867DD;
}

.theme-theme6 .bs-datepicker-body table td.week span {
    color : #5867DD
}

/* THEME-9 */
.theme-theme9 .bs-datepicker-head {
    background-color : #5867DD;
}

.theme-theme9 .bs-datepicker-body table td span.selected,
.theme-theme9 .bs-datepicker-body table td.selected span,
.theme-theme9 .bs-datepicker-body table td span[class*="select-"]:after,
.theme-theme9 .bs-datepicker-body table td[class*="select-"] span:after {
    background-color : #5867DD;
}

.theme-theme9 .bs-datepicker-body table td.week span {
    color : #5867DD
}

/* THEME-7 */
.theme-theme7 .bs-datepicker-head {
    background-color : #5867DD;
}

.theme-theme7 .bs-datepicker-body table td span.selected,
.theme-theme7 .bs-datepicker-body table td.selected span,
.theme-theme7 .bs-datepicker-body table td span[class*="select-"]:after,
.theme-theme7 .bs-datepicker-body table td[class*="select-"] span:after {
    background-color : #5867DD;
}

.theme-theme7 .bs-datepicker-body table td.week span {
    color : #5867DD
}

/* THEME-10 */
.theme-theme10 .bs-datepicker-head {
    background-color : #5867DD;
}

.theme-theme10 .bs-datepicker-body table td span.selected,
.theme-theme10 .bs-datepicker-body table td.selected span,
.theme-theme10 .bs-datepicker-body table td span[class*="select-"]:after,
.theme-theme10 .bs-datepicker-body table td[class*="select-"] span:after {
    background-color : #5867DD;
}

.theme-theme10 .bs-datepicker-body table td.week span {
    color : #5867DD
}

/* THEME-12 */
.theme-theme12 .bs-datepicker-head {
    background-color : #5867DD;
}

.theme-theme12 .bs-datepicker-body table td span.selected,
.theme-theme12 .bs-datepicker-body table td.selected span,
.theme-theme12 .bs-datepicker-body table td span[class*="select-"]:after,
.theme-theme12 .bs-datepicker-body table td[class*="select-"] span:after {
    background-color : #5867DD;
}

.theme-theme12 .bs-datepicker-body table td.week span {
    color : #5867DD
}

/*# sourceMappingURL=bs-datepicker.css.map */
